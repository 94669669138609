.dropdown-container {
}

.page-dropdown > button {
    width: 300px !important;
    height: 55px !important;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    border-color: #ced4da;
    border-radius: 4px;
    padding-right: 14px;
    cursor: text !important;
    box-sizing: border-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
}

.dropdown-label {
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-overflow: ellipsis;
    z-index: 1;
    margin-left: 16px;
    margin-top: -9px;
    position: absolute;
    background-color: white;
}

.dropdown-label-disabled {
    color: darkgray;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-overflow: ellipsis;
    z-index: 1;
    margin-left: 16px;
    margin-top: -9px;
    position: absolute;
    background-color: white;
}

.btn.show {
    background-color: white !important;
    border-color: blue !important;
}

.btn:first-child:active{
    background-color:white !important;
}

.modal-input {
    border-color: darkgray !important;
}

.modal-input::placeholder {
    color: black !important;
    font-size: 1rem;
}

.modal-input-disabled::placeholder {
    color: darkgray !important;
    font-size: 1rem;
}

.dropcontainer {
    margin-top: 10px;
}

.component > button.show, .component > button:hover {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.87);
}

.modalAddButton {
    font-size: 30px !important;
    height: 55px;
    width: 55px;
    line-height: 30px !important;
    position: fixed;
    bottom: 35px;
    right: 35px;
    z-index: 1;
}

.titleRow {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 18px;
    margin-left: 8px;
    font-size: 20px;
    font-weight: bold;
    color: #666;
    align-items: center;
}

.qa-badge {
    width: 100%;
    height: 25px;
    line-height: 15px !important;
    font-size: 15px !important;
}

.cellPass {
    background-color: lawngreen;
}

.cellFail {
    background-color: red;
}

.cellIncomplete {
    background-color: lightgrey;
}

.assetCheck .form-check-input {
    width: 2em;
    height: 2em;
    border: 2px solid #36c;
}

.check-action-container {
    float: left !important;
    width: 155px !important;
}

.check-action-container Button {
    width: 147px;
    text-align: left;
    height: 35px;
}

.assetInfo > .dropdown > .dropdown-toggle:after {
    display: none !important;
}

.assetInfo > .dropdown > .dropdown-menu {
    height: 400px;
    overflow: scroll;
    width: 700px
}