.row-container {
    border-top: 1px solid;
    /*height: max-content;*/
    width: 100%;
}

.roi-header {
    height: 100px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /*float: left;*/
    width: 100%;
}

.roi-header-logo {
    margin-left: 12px;
    height: 120px;
}

.roi-header-text {
    text-align: center;
    position: absolute;
    width: 95%;
    font-size: 22px;
    font-weight: bold;
}

.roi-body {
    /*float: left;*/
    width: 100%;
}

.roi-body-header {
    height: 25px;
    background-color: #3366cc;
    border-bottom: 2px solid;
    float: left;
    width: 100%;
    margin-top: 10px;
    color: white;
}

.roi-body-header-text {
    line-height: 25px;
    font-weight: bold;
    margin-left: 3px;
}

.roi-body-container-full {
    float: left;
    border: 1px solid;
    width: 100%;
}

.roi-body-container-half {
    float: left;
    border: 1px solid;
    width: 49%;
    /*height: 86px;*/
}

.roi-body-container-half-sealing {
    float: left;
    border: 1px solid;
    width: 49%;
    height: 225px;
}

.roi-body-container-table-full {
    float: left;
    border: 1px solid;
    width: 100%;
}

.roi-body-container-table-half {
    float: left;
    border: 1px solid;
    width: 49%;
    height: 124px
}

.roi-body-container-quarter {
    float: left;
    border: 1px solid;
    width: 23.5%;
    height: 100px
}

.roi-body-container-right {
    margin-left: 2%;
}

.roi-body-container-label {
    font-weight: bold;
    margin-left: 5px;
    width: 158px;
    float: left;
}

.roi-body-container-label-short {
    font-weight: bold;
    margin-left: 5px;
    float: left;
}

.roi-body-container-label-longer {
    font-weight: bold;
    margin-left: 5px;
    width: 175px;
    float: left;
}

.roi-body-container-label-sealing {
    margin-left: 5px;
    width: 430px;
    float: left;
}

.roi-body-container-label-full {
    font-weight: bold;
    margin-left: 5px;
    width: 150px;
}

.roi-body-container-value {
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
    margin-left: 5px;
}

.roi-body-container-spacer {
    margin-left: 5px;
    width: 100%;
}

.roi-body-container-signed {
    margin-left: 5px;
    width: 150px;
    border-bottom: 1px solid;
    float: left;

}

.value-red {
    color: red;
}

.value-blue {
    color: deepskyblue;
}

.value-green {
    color: forestgreen !important;
}

.value-orange {
    color: orange !important;
}

.value-purple {
    color: mediumpurple !important;
}

.roi-body-container-data-half{
    width: 48%;
    float: left;
}

.roi-body-container-value div input[type=checkbox] {
    border: 2px solid #36c;
}

.roiTableStyle tr th {
    padding-left: 5px;
}