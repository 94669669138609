.modalStyle {
    background-color: rgba(0,0,0,0.3) !important;
}

.modalinspection {
    z-index: 1;
    height: 55px;
    margin-left: 90%;
    position: sticky;
    font-size: 30px !important;
    line-height: 30px !important;
    top: 20px;
}

.d-grid button {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    min-height: 50px;
    border-color: #adb5bd;
    color: black;
}

.d-grid select {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    min-height: 50px;
    border-color: #adb5bd;
    color: black;
}

.d-grid button:hover {
    background-color: #F5F5F5;
    color: black;
}

.dropdown-toggle:after {
    position: absolute;
    top: 50%;
    right: 20px;
}

.pdfButton .dropdown-toggle:after {
    position: absolute;
    top: 50%;
    right: 0px;
}

.dropdown-menu.show {
    width: 100%;
}

.pdfButton .dropdown-menu.show {
    width: 180px;
}

#multiselectContainerReact .searchWrapper {
    font-size: 1.25rem;
    border-radius: var(--bs-border-radius-lg);
}

.multiSelectContainer input {
    cursor: pointer;
}